.textLabel {
  font-family: 'Inter';
  font-style: 'normal';
  font-weight: 400;
  font-size: 14px;
  line-height: '143%';
  letter-spacing: 0.17;
  text-overflow: 'ellipsis';
}

.heading {
  font-family: 'Inter';
  font-style: 'normal';
  font-weight: 500;
  font-size: 20px;
  line-height: '160%';
  letter-spacing: 0.15;
  width: 389px;
  height: 32px;
}

.tile-card {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}